import React, { useState, useEffect } from 'react';
import { ScrollText, Leaf, Apple, Map, Phone, Menu, X } from 'lucide-react';

import logo from './assets/gmela.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Gestisce lo stile della navbar in base allo scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Lista delle sezioni per la navigazione
  const navItems = [
    { label: 'Azienda', href: '#azienda' },
    { label: 'Giuggiola', href: '#giuggiola' },
    { label: 'Prodotti', href: '#prodotti' },
    { label: 'Territorio', href: '#territorio' },
    { label: 'Processo', href: '#processo' },
    { label: 'Contatti', href: '#contatti' },
  ];

  // Funzione per lo scroll fluido
  const scrollToSection = (e, href) => {
    e.preventDefault();
    const element = document.querySelector(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsOpen(false);
    }
  };

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 bg-white shadow-md`}>
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="flex items-center justify-between h-20">
          <a href="#">
            <img 
              src={logo} 
              alt="gmela" 
              className={`h-12`}
              //  transition-opacity duration-300 ${
              //   isScrolled ? 'opacity-90' : 'opacity-100'
              
            />
          </a>

          {/* Menu Desktop */}
          <div className="hidden md:flex space-x-8">
            {navItems.map(item => (
              <a
                key={item.href}
                href={item.href}
                onClick={(e) => scrollToSection(e, item.href)}
                className={`transition-colors duration-300 hover:text-green-600 ${
                  'text-gray-800'
                }`}
              >
                {item.label}
              </a>
            ))}
          </div>

          {/* Menu Mobile Toggle */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={`md:hidden ${'text-gray-700'}`}
          >
            {isOpen ? <X /> : <Menu />}
          </button>
        </div>

        {/* Menu Mobile */}
        {isOpen && (
          <div className="md:hidden bg-white absolute top-20 left-0 w-full shadow-lg">
            <div className="px-4 py-2 space-y-2">
              {navItems.map(item => (
                <a
                  key={item.href}
                  href={item.href}
                  onClick={(e) => scrollToSection(e, item.href)}
                  className="block py-2 text-gray-700 hover:text-green-600"
                >
                  {item.label}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

const LandingPage = () => {
  return (
    <div className="w-full">
      <Navbar />

      <section className="relative h-screen flex items-center justify-center bg-cover bg-center bg-no-repeat" 
        style={{ backgroundImage: `url('albero-giuggiola.jpg')` }}
      >
        <div className="absolute inset-0 bg-black/10" />
        <div className="relative z-10 text-center text-gray-100 p-8"
        style={{ textShadow: `0px 0px 7px rgba(0, 0, 0, 0.9)`}}>
          <h1 className="text-5xl font-serif mb-4">Un Frutto Antico, Un Sapore Unico</h1>
          <p className="text-2xl font-light">
            Lasciati incantare dalla sua inconfondibile dolcezza...
          </p>
        </div>
      </section>

      {/* Azienda */}
      <section id="azienda" className="py-24 bg-white scroll-mt-20">
        <div className="container mx-auto px-4 max-w-5xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <h2 className="text-4xl font-serif">Azienda Agricola Pirazzini</h2>
              <p className="text-gray-700">Storia o breve descrizione dell'azienda.</p>
            </div>
            <div className="h-96 rounded-lg bg-cover shadow-lg"
              style={{ backgroundImage: `url('cascina.jpg')` }}
            ></div>
          </div>
        </div>
      </section>

      {/* La Giuggiola Mela */}
      <section id="giuggiola" className="py-24 bg-green-50 scroll-mt-20">
        <div className="container mx-auto px-4 max-w-5xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div className="bg-white h-96 rounded-lg shadow-lg order-2 md:order-1 bg-cover"
              style={{ backgroundImage: `url('giuggiola.jpg')` }}
            ></div>
            <div className="space-y-6 order-1 md:order-2">
              <h2 className="text-4xl font-serif">La Giuggiola Mela</h2>
              <p className="text-gray-700">
La Giuggiola Mela è un frutto raro e prezioso, celebre per il suo sapore unico che unisce la freschezza della mela alla dolcezza del miele. Croccante al primo morso, diventa vellutata e avvolgente man mano che matura. Ricca di vitamine e antiossidanti, è un alleato naturale per il benessere, aiutando a rafforzare il sistema immunitario e favorire la digestione. Un piccolo tesoro della natura che incanta i sensi e nutre il corpo.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* I Nostri Prodotti */}
      <section id="prodotti" className="py-24 bg-white scroll-mt-20">
        <div className="container mx-auto px-4 max-w-5xl">
          <h2 className="text-4xl font-serif text-center mb-16">I Nostri Prodotti</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="bg-green-50 p-6 rounded-lg text-center space-y-4">
                <div className="w-32 h-32 mx-auto bg-white rounded-full flex bg-cover"
                  style={{backgroundImage: 'url(giuggiola.jpg)'}}
                >
                </div>
                <h3 className="font-serif text-xl">Giuggiole Fresche</h3>
              </div>
              <div className="bg-green-50 p-6 rounded-lg text-center space-y-4">
                <div className="w-32 h-32 mx-auto bg-white rounded-full flex bg-cover"
                  style={{backgroundImage: 'url(grappa.jpg)'}}
                >
                </div>
                <h3 className="font-serif text-xl">Grappa alla Giuggiola</h3>
              </div>
              <div className="bg-green-50 p-6 rounded-lg text-center space-y-4">
                <div className="w-32 h-32 mx-auto bg-white rounded-full flex bg-cover"
                  style={{backgroundImage: 'url(liquore.jpg)'}}
                >
                </div>
                <h3 className="font-serif text-xl">Liquore alla Giuggiola</h3>
              </div>
              <div className="bg-green-50 p-6 rounded-lg text-center space-y-4">
                <div className="w-32 h-32 mx-auto bg-white rounded-full flex bg-cover"
                  style={{backgroundImage: 'url(cioccolato.jpg)'}}
                >
                </div>
                <h3 className="font-serif text-xl">Cioccolatini alla Giuggiola</h3>
              </div>
          </div>
        </div>
      </section>

      {/* Il Nostro Territorio */}
      <section id="territorio" className="py-24 bg-green-50 scroll-mt-20">
        <div className="container mx-auto px-4 max-w-5xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <h2 className="text-4xl font-serif">Il Nostro Territorio</h2>
              <p className="text-gray-700">
La campagna della pianura faentina è rinomata per la straordinaria fertilità dei suoi terreni. Questo suolo, naturalmente equilibrato e ben drenato, offre l'ambiente ideale per colture di alta qualità, come la Giuggiola Mela. Grazie alla composizione minerale del terreno e al clima favorevole, le piante qui crescono rigogliose, assorbendo sostanze nutritive che ne esaltano il sapore e la qualità. È proprio questa terra generosa che rende i frutti così gustosi e ricchi di proprietà benefiche.
              </p>
            </div>
            <div className="bg-white h-96 rounded-lg shadow-lg bg-cover"
              style={{ backgroundImage: `url('campagna.jpg')` }}
            ></div>
          </div>
        </div>
      </section>

      {/* Processo Produttivo */}
      <section id="processo" className="py-24 bg-white scroll-mt-20">
        <div className="container mx-auto px-4 max-w-5xl">
          <h2 className="text-4xl font-serif text-center mb-16">Il Processo Produttivo</h2>
              <p className="text-gray-700">
Il processo produttivo della Giuggiola Mela è un’arte che richiede pazienza e dedizione. In primavera, gli alberi vengono curati con attenzione, favorendo una crescita sana. Durante l'estate, i frutti si sviluppano protetti con metodi naturali, senza forzature. L’autunno segna il momento più importante: la raccolta manuale, frutto per frutto, solo quando hanno raggiunto la maturazione perfetta. In inverno, la terra riposa, mentre i frutti raccolti vengono trasformati in delizie artigianali. Ogni fase è svolta rispettando i ritmi della natura, per preservare la qualità e il sapore autentico.
              </p>
        </div>
      </section>

      {/* Contattaci */}
      <section id="contatti" className="py-24 bg-green-50 scroll-mt-20">
        <div className="container mx-auto px-4 max-w-5xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="space-y-6">
              <h2 className="text-4xl font-serif">Contattaci</h2>
              <div className="space-y-4">
                <div className="flex items-center space-x-4">
                  <Map className="w-6 h-6 text-green-600" />
                  <span>Via [indirizzo], Faenza (RA)</span>
                </div>
                <div className="flex items-center space-x-4">
                  <Phone className="w-6 h-6 text-green-600" />
                  <span>[telefono]</span>
                </div>
              </div>
            </div>
            <form className="bg-white p-8 rounded-lg shadow-lg space-y-6">
              <input type="text" placeholder="Nome" className="w-full p-3 border rounded" />
              <input type="email" placeholder="Email" className="w-full p-3 border rounded" />
              <textarea placeholder="Messaggio" className="w-full p-3 border rounded h-32"></textarea>
              <button className="w-full bg-green-600 text-white py-3 rounded hover:bg-green-700 transition">
                Invia Messaggio
              </button>
            </form>
          </div>
        </div>
      </section>

      <footer className="bg-green-900 text-white py-12">
        <div className="container mx-auto px-4 max-w-5xl">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="font-serif text-2xl mb-4">
              <img 
                src={logo} 
                alt="GMela Logo" 
                className={`h-12 transition-opacity duration-300`}
              />
              </h3>
              <p className="text-green-100">motto</p>
            </div>
            <div>
              <h4 className="font-serif text-xl mb-4">Contatti</h4>
              <address className="text-green-100 not-italic">
                Via [indirizzo]<br />
                Faenza (RA)<br />
                [telefono]<br />
                [email]
              </address>
            </div>
            <div>
              <h4 className="font-serif text-xl mb-4">Social? Siti Amici?</h4>
              <div className="flex space-x-4">
                {/* Social icons would go here */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;